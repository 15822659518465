import * as am5 from '@amcharts/amcharts5';
import xyChartToggleState from '@/charts/xyChartToggleState';

// see themeSoundsAmerican.ts

export default function xyChartCreateButton(root: am5.Root, data: any, state: any) {
  const button: am5.Button = am5.Button.new(root, {
    themeTags: ['button-toggle-state'],
    label: am5.Label.new(root, { text: state.caption }),
    cursorOverStyle: 'pointer',
  });

  // setting default states seems to be redundant, but the 'active' state doesn't fire up without it.
  const bg = button.get('background');
  if (bg) {
    bg.states.create('default', {}).set('fill', root.interfaceColors.get('primaryButton'));
    bg.states.create('active', {}).set('fill', root.interfaceColors.get('primaryButtonActive'));
    bg.states.create('hover', {}).set('fill', root.interfaceColors.get('primaryButtonHover'));
    bg.states.create('down', {}).set('fill', root.interfaceColors.get('primaryButtonDown'));
  }

  button.events.on('click', () => {
    xyChartToggleState(root, data, state.id);
  });

  return button;
}
