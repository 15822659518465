import * as am5 from '@amcharts/amcharts5';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated';
import * as GLB from '@/charts/globals';

// https://www.amcharts.com/docs/v5/concepts/themes/creating-themes/
// https://github.com/amcharts/amcharts5/tree/master/src/.internal/themes

export default class SoundsAmericanTheme extends AnimatedTheme {
  setupDefaultRules() {
    super.setupDefaultRules();

    this.rule('ColorSet')
      .setAll({
        colors: [
          am5.color(0x59afe1), // blue
          am5.color(0x4cd3d6), // cyan
          am5.color(0x4ed1a1), // green
          am5.color(0xffbd17), // yellow
          am5.color(0xfc8363), // orange
          am5.color(0xe24b88), // fuchsia
          am5.color(0xb18be8), // violet
          am5.color(0xdce1e5), // gray
        ],
        reuse: true,
      });

    this.rule('InterfaceColors')
      .setAll({
        stroke: GLB.COLOR_STROKE, // am5.color(0xe5e5e5),
        // fill: am5.color(0xff0000),
        primaryButton: GLB.COLOR_FILL_LIGHT,
        primaryButtonHover: GLB.COLOR_SA_ORANGE,
        primaryButtonDown: GLB.COLOR_SA_ORANGE,
        primaryButtonActive: GLB.COLOR_SA_YELLOW,
        primaryButtonText: GLB.COLOR_TEXT, // TODO: does not work
        primaryButtonStroke: GLB.COLOR_STROKE,
        // secondaryButton: am5.color(0xd9d9d9),
        // secondaryButtonHover: am5.color(0xa3a3a3),
        // secondaryButtonDown: am5.color(0x8d8d8d),
        // secondaryButtonActive: am5.color(0xe6e6e6),
        // secondaryButtonText: am5.color(0x000000),
        // secondaryButtonStroke: am5.color(0xffffff),
        // grid: am5.color(0xff0000),
        // background: am5.color(0xff0000),
        // alternativeBackground: am5.color(0x000000),
        text: GLB.COLOR_TEXT, // am5.color(0x00ff00),
        // alternativeText: am5.color(0xffffff),
        // disabled: am5.color(0xadadad),
        // positive: am5.color(0x50b300),
        // negative: am5.color(0xb30000)
      });

    // Common stuff

    this.rule('Label')
      .setAll({
        // fontSize: GLB.FONT_SIZE_REGULAR,
        // fill: GLB.COLOR_TEXT
      });

    // XY Chart

    this.rule('Label', ['axis', 'x'])
      .setAll({
        fontSize: GLB.FONT_SIZE_REGULAR,
      });

    this.rule('AxisRendererX')
      .setAll({
        minGridDistance: 20,
      });

    // Pie Chart

    this.rule('PieChart')
      .setAll({
        innerRadius: am5.percent(50),
      });

    this.rule('Slice', ['pie', 'series'])
      .setAll({
        stroke: GLB.COLOR_STROKE,
        strokeWidth: GLB.STROKE_REGULAR,
        cornerRadius: GLB.RADIUS_REGULAR,
      });

    this.rule('Tick', ['pie', 'series'])
      .setAll({
        forceHidden: true,
      });

    this.rule('Label', ['pie', 'series'])
      .setAll({
        fontSize: GLB.FONT_SIZE_REGULAR,
        fontWeight: '500',
      });

    this.rule('Label', ['donut-center-label'])
      .setAll({
        fill: GLB.COLOR_TEXT,
        fontSize: '3rem', // TODO: make responsive ?
        text: '-',
        textAlign: 'center',
        position: 'relative',
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        fontWeight: '500',
      });

    // Tooltip

    this.rule('Tooltip')
      .setAll({
        // position: "absolute",
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        // paddingTop: 9,
        // paddingBottom: 8,
        // paddingLeft: 10,
        // paddingRight: 10,
        // marginBottom: 5,
        // pointerOrientation: "vertical",
        // centerX: p50,
        // centerY: p50,
        // animationEasing: $ease.out($ease.cubic),
        // exportable: false
        // layer: 100
      });

    this.rule('PointedRectangle', ['tooltip', 'background'])
      .setAll({
        fill: GLB.COLOR_FILL_LIGHT,
        stroke: GLB.COLOR_STROKE, // this needs to be explicitly set
        strokeWidth: GLB.STROKE_REGULAR,
        fillOpacity: 1,
      });

    this.rule('Label', ['tooltip'])
      .setAll({
        // fontSize: GLB.FONT_SIZE_REGULAR,
        fill: GLB.COLOR_TEXT, // this needs to be explicitly set
        fontWeight: '500',
      });

    // Button

    this.rule('Label', ['button-toggle-state'])
      .setAll({
        fontSize: GLB.FONT_SIZE_SMALL,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      });

    this.rule('RoundedRectangle', ['button-toggle-state'/* , "background" */])
      .setAll({
        cornerRadiusTL: GLB.RADIUS_SMALL,
        cornerRadiusTR: GLB.RADIUS_SMALL,
        cornerRadiusBR: GLB.RADIUS_SMALL,
        cornerRadiusBL: GLB.RADIUS_SMALL,
        // stroke: GLB.COLOR_STROKE,
        // strokeWidth: GLB.STROKE_LIGHT,
        // fill: GLB.COLOR_FILL_LIGHT,
        // fillOpacity: 0.7
      });

    this.rule('Button', ['button-toggle-state'])
      .setAll({
        marginRight: GLB.MARGIN_SMALL,
        marginLeft: GLB.MARGIN_SMALL,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 10,
        paddingRight: 10,
        // interactive: true,
        // layout: horizontalLayout,
        // interactiveChildren: false,
        // setStateOnChildren: true,
        // focusable: true
      });
  }
}
