import * as GLB from '@/charts/globals';

export default function xyChartAddRange(xAxis: any, lbl: any, start: any, end: any) {
  const range = xAxis.makeDataItem({});
  xAxis.createAxisRange(range);

  range.set('category', start);
  range.set('endCategory', end);

  const label = range.get('label');
  label.setAll({
    text: lbl,
    dy: 25,
    // fontWeight: '600',
    tooltipText: lbl,
  });

  const tick = range.get('tick');
  tick.setAll({
    visible: true,
    strokeOpacity: GLB.OPACITY_GRID,
    length: GLB.RANGE_TICK_LENGTH,
    location: 0,
  });

  const grid = range.get('grid');
  grid.setAll({ strokeOpacity: GLB.OPACITY_GRID });
}
