export function isIOS(): boolean {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  }
  return !!(navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform));
}

export function isIosSafari(): boolean {
  const ua = window.navigator.userAgent;
  const webkit = !!ua.match(/WebKit/i);
  return isIOS() && webkit;
}
