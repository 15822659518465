import * as am5 from '@amcharts/amcharts5';

export default function csDonutChartCreateLegend(root: am5.Root) {
  const propertiesLegend = {
    y: am5.p50,
    centerY: am5.p50,
    x: null as any,
    centerX: null as any,
    marginBottom: 10,
    layout: root.verticalLayout,
    // TODO: remove tmp background
    // background: am5.Rectangle.new(root, {
    //   fill: am5.color(0x000000),
    //   fillOpacity: 0.1
    // })
  };

  // TODO: set font size

  const propertiesLegendMarkers = {
    cornerRadiusTL: 20,
    cornerRadiusTR: 20,
    cornerRadiusBL: 20,
    cornerRadiusBR: 20,
  };

  const legend = am5.Legend.new(root, propertiesLegend as any); // todo: propertiesLegend doesn't fit acceptable type

  legend.markerRectangles.template.setAll(propertiesLegendMarkers);

  // we don't use value labels anyway,  le't spare some space
  legend.valueLabels.template.set('width', 10);

  return legend;
}
