import fetchChartData from '@/charts/fetchChartData';
import drawResponsiveCSDonutChart from '@/charts/drawResponsiveCSDonutChart';
import drawInteractiveXYChart from '@/charts/drawInteractiveXYChart';

export default function registerCharts() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.fetchChartData = fetchChartData;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.drawInteractiveXYChart = drawInteractiveXYChart;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.drawResponsiveCSDonutChart = drawResponsiveCSDonutChart;
}
