import type { DeviceType } from '@/types/DeviceType';

export function deviceHasTouchPoints() {
  return 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
}

export function deviceHasOrientation() {
  return 'orientation' in window;
}

export function navigatorHasPlatform() {
  return 'platform' in navigator;
}

export function deviceHasCoarsePointer() {
  const mQ = matchMedia('(pointer:coarse)');
  if (mQ && mQ.media === '(pointer:coarse)') {
    return mQ.matches;
  }
  return false;
}

export function userAgentHasMobile() {
  const ua = navigator.userAgent;
  return /\b(mobile)\b/i.test(ua);
}

export function userAgentHasTablet() {
  const ua = navigator.userAgent;
  return /\b(tablet)\b/i.test(ua);
}

export function userAgentHasAppleDevice() {
  const ua = navigator.userAgent;
  return /\b(iPhone|iPad|iPod)\b/i.test(ua);
}

export function platformHasMacIntel() {
  if (!navigatorHasPlatform()) return false;
  const { platform } = navigator;
  return /\b(MacIntel)\b/i.test(platform);
}

export function getDeviceType(): DeviceType {
  if (deviceHasTouchPoints()
    || deviceHasOrientation()
    || deviceHasCoarsePointer()
    || userAgentHasMobile()
    || userAgentHasTablet()) return 'mobile';
  return 'desktop';
}

export function deviceIsApple() {
  return userAgentHasAppleDevice() || (getDeviceType() === 'mobile' && platformHasMacIntel());
}
