import * as am5 from '@amcharts/amcharts5';

// custom funcitonality - toggle pie slices pullout on legend clicks

export default function csDonutChartLegendPulloutOnClick(legend: am5.Legend) {
  legend.itemContainers.each((itemContainer: am5.Container) => {
    itemContainer.events.removeType('click');
    itemContainer.events.on('click', (event) => {
      const { dataItem } = event.target;

      if (dataItem) {
        const seriesDataItem: any = dataItem.dataContext;
        if (seriesDataItem) {
          const slice = seriesDataItem.get('slice');
          if (slice && slice.set) {
            slice.set('active', !slice.get('active'));
          }
        }
      }
    });
  });

  return legend;
}
