import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const Website = () => import(/* webpackChunkName: "website" */'../views/Website.vue');
// const ComingSoon = () => import(/* webpackChunkName: "coming-soon" */'../views/ComingSoon.vue');
const Home = () => import(/* webpackChunkName: "home" */'../views/website/Home.vue');
const Articles = () => import(/* webpackChunkName: "articles" */'../views/website/Articles.vue');

const Article = () => import(/* webpackChunkName: "article" */'../views/website/Article.vue');
const Page404 = () => import(/* webpackChunkName: "404" */'../views/website/404.vue');
const Tests = () => import(/* webpackChunkName: "tests" */'../views/test/Tests.vue');
const WebsiteTests = () => import(/* webpackChunkName: "tests" */'../views/website/test/WebsiteTests.vue');
const DeviceTypeTest = () => import(/* webpackChunkName: "device_type_test" */'../views/test/DeviceTypeTest.vue');
const BlankPageTest = () => import(/* webpackChunkName: "blank-page-test" */'../views/website/test/BlankPageTest.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/test',
    name: 'Tests',
    component: Tests,
    children: [
      {
        path: 'device_type_test',
        name: 'DeviceTypeTest',
        component: DeviceTypeTest,
      },
    ],
  },
  {
    path: '/',
    name: 'Website',
    component: Website,
    children: [
      {
        path: 'website_test',
        name: 'WebsiteTests',
        component: WebsiteTests,
        children: [
          {
            path: 'blank_page_test',
            name: 'BlankPageTest',
            component: BlankPageTest,
          },
        ],
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: 'articles',
        name: 'Articles',
        component: Articles,
      },
      {
        path: 'article/:slug',
        name: 'Article',
        component: Article,
      },
      {
        path: '/:catchAll(.*)',
        name: '404',
        component: Page404,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
