import * as am5 from '@amcharts/amcharts5';
import xyChartSortData from '@/charts/xyChartSortData';
import xyChartAddRanges from '@/charts/xyChartAddRanges';
import xyChartAddRangesSingle from '@/charts/xyChartAddRangesSingle';
import xyChartFindTitle from '@/charts/xyChartFindTitle';
import xyChartFindButtonsContainer from '@/charts/xyChartFindButtonsContainer';

export default function xyChartToggleState(root: am5.Root, dataAll: any, stateId: any) {
  const state = dataAll.states[stateId];
  const data = dataAll.chart;

  const labelTitle:am5.Label | null = xyChartFindTitle(root);
  if (labelTitle) labelTitle.set('text', state.title);

  xyChartSortData(root, data, state.sort_key1, state.sort_key2, state.sort_direction1, state.sort_direction2);

  // it's a bit of a stretch, but we assume that one state charts don't have ranges
  if (dataAll.states.length > 1) {
    if (state.ranges_flag) {
      xyChartAddRanges(root, data, state.range_key1, state.range_key2);
    } else {
      xyChartAddRangesSingle(root, data, state.range_key1, state.range_key2);
    }

    const containerButtons: am5.Container | null = xyChartFindButtonsContainer(root);

    if (containerButtons) {
      for (let i = 0; i < containerButtons.children.length; i += 1) {
        const containerButton = containerButtons.children.getIndex(i);
        if (containerButton) containerButton.set('active', i === stateId);
      }
      // containerButtons.children.each(child=>{child.set("active", child == button);});
    }
  }
}
