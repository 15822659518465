import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import ResponsiveTheme from '@amcharts/amcharts5/themes/Responsive';

import * as GLB from '@/charts/globals';
import reportError from '@/charts/reportError';
import preprocessData from '@/charts/preprocessData';
import maybeDisposeRoot from '@/charts/maybeDisposeRoot';
import csDonutChartCreateLegend from '@/charts/csDonutChartCreateLegend';
import SoundsAmericanTheme from '@/charts/themeSoundsAmerican';
import csDonutChartLegendPulloutOnClick from './csDonutChartLegendPulloutOnClick';

export default function csDonutChartMake(data: any, chartElementId: string) {
  // TODO: think about this:
  if (data.status !== '') {
    reportError(data.status, chartElementId);
    return;
  }

  // preprocess data
  preprocessData(data.chart);

  maybeDisposeRoot(chartElementId);

  // Create root element
  const root = am5.Root.new(chartElementId);
  root.container.set('layout', root.horizontalLayout);

  // Responsive Theme & Rules
  // https://www.amcharts.com/docs/v5/concepts/responsive/
  const responsiveTheme = ResponsiveTheme.newEmpty(root);

  // Create legend with responsive rules
  const legend = csDonutChartCreateLegend(root);

  responsiveTheme.addRule({
    // relevant: am5themes_Responsive.widthL,
    relevant(width, height) {
      return width < GLB.RESPONSIVE_THRESHOLD;
    },
    applying() {
      root.container.set('layout', root.verticalLayout);
      legend.setAll({
        y: null,
        centerY: undefined,
        centerX: am5.p50,
        x: am5.p50,
        layout: root.gridLayout,

      });
    },
    removing() {
      root.container.set('layout', root.horizontalLayout);
      legend.setAll({
        y: am5.p50,
        centerY: am5.p50,
        x: null,
        centerX: undefined,
        layout: root.verticalLayout,
      });
    },
  });

  // Set chart themes
  const soundsAmericanTheme = SoundsAmericanTheme.new(root);
  root.setThemes([soundsAmericanTheme, responsiveTheme]);

  // set properties (chart, pie series, slices, labels, etc. )

  const propertiesSeries = {
    name: 'Series',
    valueField: 'words',
    categoryField: 'grapheme',
    alignLabels: false,
    fillField: 'color', // TODO: add fallback to custom theme ColorSet
    legendLabelText: '[bold]{grapheme}[/]: {examples}',
    legendValueText: '', // hiding legend values
  };

  const propertiesLabels = {
    radius: 45, // TODO: labels positioning is not ideal
    text: "{grapheme}\n{valuePercentTotal.formatNumber('#.')}%",
    inside: true,
  };

  const propertiesTooltip = {
    labelText: "{valuePercentTotal.formatNumber('#.')}% - {grapheme}[/]\n{examples}",
  };

  // create and setup data series

  const series = am5percent.PieSeries.new(root, propertiesSeries);
  series.labels.template.setAll(propertiesLabels as any); // todo: propertiesLabels doesn't fit acceptable type
  const tooltip = am5.Tooltip.new(root, propertiesTooltip);
  series.set('tooltip', tooltip);

  // change default slices behavior: only one slice can be "active" (pulled out)
  series.slices.template.events.on('click', (ev) => {
    series.slices.each((slice) => {
      if (slice !== ev.target && slice.get('active')) {
        slice.set('active', false);
      }
    });
  });

  // hide labels on small slices (via adapter)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  series.labels.template.adapters.add('visible', (visible, target) => target.dataItem.get('valuePercentTotal') > 5);

  // create chart and title (we're almost ready ;)

  const chart = am5percent.PieChart.new(root, {});
  const labelTitle = am5.Label.new(root, { themeTags: ['donut-center-label'] });

  // get everything together

  root.container.children.push(chart);
  chart.series.push(series);
  chart.children.push(labelTitle);
  root.container.children.push(legend);

  // finally set actual data

  series.data.setAll(data.chart);
  legend.data.setAll(series.dataItems);
  labelTitle.set('text', data.labels[0].title);

  // change default legend behavior: toggle active pie slice on legend click

  csDonutChartLegendPulloutOnClick(legend);

  // let it appear with style

  chart.appear(1600, 100);
  legend.appear(1600, 100);
}
