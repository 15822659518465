import { createApp } from 'vue';
import registerCharts from '@/charts/registerCharts';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap';

store.getters.timeHandler.log('App has been started');
registerCharts();
createApp(App).use(store).use(router).mount('#app');
