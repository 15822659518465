export default async function fetchChartData(url: string) {
  const response = await fetch(url);
  let data = {} as any;

  if (response.ok) {
    data = await response.json();
    data.status = '';
  } else {
    data.status = `ERROR: ${url} ${response.status} ${response.statusText}`;
  }
  return data;
}
