import * as am5 from '@amcharts/amcharts5';
import xyChartFindChart from '@/charts/xyChartFindChart';
import cmp from '@/charts/cmp';

export default function xyChartSortData(root: am5.Root, data: any, key1: any, key2: any, order1 = false, order2 = false) {
  const chart = xyChartFindChart(root);
  const xAxis = (chart as any).xAxes.getIndex(0);
  const series = (chart as any).series.getIndex(0);

  // sort data
  data.sort((a: any, b: any) => cmp(
    [(order1 ? -1 : 1) * cmp(a[key1], b[key1]), (order2 ? -1 : 1) * cmp(a[key2], b[key2])],
    [(order1 ? -1 : 1) * cmp(b[key1], a[key1]), (order2 ? -1 : 1) * cmp(b[key2], a[key2])],
  ));

  // update axes and series
  xAxis.data.setAll(data);
  series.data.setAll(data);
}
