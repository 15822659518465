import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as GLB from '@/charts/globals';

import xyChartAddButtons from '@/charts/xyChartAddButtons';
import xyChartToggleState from '@/charts/xyChartToggleState';
import xyChartGetYAxisMax from '@/charts/xyChartGetYAxisMax';
import SoundsAmericanTheme from '@/charts/themeSoundsAmerican';

export default function xyChartMake(data: any, elementId: string, highlightedSound: string) {
  // Create root element
  const root = am5.Root.new(elementId);

  // Set themes
  root.setThemes([SoundsAmericanTheme.new(root)]);

  root.container.setAll({ layout: root.verticalLayout });

  // Create chart
  const chart = am5xy.XYChart.new(root, {
    // panX: false,
    // panY: false,
    // wheelX: "panX",
    // wheelY: "zoomX",
    layout: root.verticalLayout,
    paddingTop: 0, // default: 16
    paddingBottom: 0, // default: 16
    // paddingLeft: 0, // default: 20
    // paddingRight: 0 // default: 20
  });

  const propertiesYAxis = {
    renderer: am5xy.AxisRendererY.new(root, {}),
    min: 0,
    max: xyChartGetYAxisMax(data),
    strictMinMax: true,
  };

  const propertiesXAxis = {
    maxDeviation: 0,
    renderer: am5xy.AxisRendererX.new(root, {}),
    categoryField: 'sound',
    strictMinMax: true,
  };

  const yAxis = am5xy.ValueAxis.new(root, propertiesYAxis as any);
  const xAxis = am5xy.CategoryAxis.new(root, propertiesXAxis as any);

  // hide Y axis labels
  yAxis.get('renderer').labels.template.set('forceHidden', true);

  // an attempt to workaround situation when x axis labels do not fit
  // xAxis.get("renderer").labels.template.setAll({
  //   oversizedBehavior: "fit",
  //   maxWidth: 50
  // });

  // Tooltip
  // https://www.amcharts.com/docs/v5/concepts/common-elements/tooltips/
  const propertiesTooltip = {
    labelText: '{tooltip}\n{frequency} words',
  };

  const propertiesColumnTemplate = {
    strokeWidth: GLB.STROKE_REGULAR,
    cornerRadiusTL: GLB.RADIUS_REGULAR,
    cornerRadiusTR: GLB.RADIUS_REGULAR,

    stroke: GLB.COLOR_STROKE,
    tooltipText: 'do not delete tooltipText parameter ;)',
    tooltipY: -6,
    interactive: true,
  };

  const propertiesSeries = {
    name: 'Series',
    xAxis,
    yAxis,
    valueYField: 'frequency',
    categoryXField: 'sound',
    sequencedInterpolation: true,
  };

  const tooltip = am5.Tooltip.new(root, propertiesTooltip) as any;

  // Create series
  const series = am5xy.ColumnSeries.new(root, propertiesSeries as any);

  series.set('tooltip', tooltip);

  series.columns.template.setAll(propertiesColumnTemplate);

  // Create custoim state on columns
  // series.columns.template.states.create("off", {
  //   fillOpacity: 0.1,
  //   strokeOpacity: 0.1
  // });

  // series.columns.template.events.on("hover", function(ev) {
  //   if (ev.target.get("fillOpacity") !== 1) {
  //     ev.target.states.applyAnimate("default");
  //   }
  //   else {
  //     ev.target.states.applyAnimate("off");
  //   }
  // });

  // ***
  // series.columns.template.states.create("hover", {
  //   fillOpacity: 1
  //   // fill: am5.color(0x297373),
  //   // stroke: am5.color(0x297373)
  // });
  // series.columns.template.states.create("default", {
  //   fillOpacity: 0.7
  //   // fill: am5.color(0x297373),
  //   // stroke: am5.color(0x297373)
  // });

  // set x-axis conteiner height
  // (we need this to avoid "hops" on states toggling)
  // https://www.amcharts.com/docs/v5/charts/xy-chart/xy-chart-containers/
  chart.bottomAxesContainer.set('height', 60);

  // Highlight maker
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  series.columns.template.adapters.add('fill', (fill, target) => {
    // target.dataItem.get("valueY")
    if (((target.dataItem as any).dataContext as any).sound === highlightedSound) {
      return GLB.COLOR_XY_COLUMN_HIGHLIGHTED;
    }
    return GLB.COLOR_XY_COLUMN_DEFAULT;
  });

  // Title
  const labelTitle = am5.Label.new(root, {
    text: 'stub title',
    fontSize: GLB.FONT_SIZE_BIG,
    fontWeight: '500',
    x: am5.percent(50),
    centerX: am5.percent(50),
    // paddingTop: 6, // default - 6
    // paddingBottom: 6,
    // marginTop: 0,
    // background: am5.Rectangle.new(root, {
    //   fill: am5.color(0xff9955),
    //   fillOpacity: 0.2
    // })
  });
  root.container.children.push(labelTitle);

  root.container.children.push(chart);
  chart.series.push(series);
  chart.yAxes.push(yAxis);
  chart.xAxes.push(xAxis);

  let initialStateIndex = 0;

  if (data.states.length > 1) {
    // Buttons container
    const containerButtons = am5.Container.new(root, {
      layout: am5.HorizontalLayout.new(root, {}),
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 6,
      paddingRight: 6,
      x: am5.percent(50),
      centerX: am5.percent(50),
      // background: am5.Rectangle.new(root, {
      //   fill: am5.color(0xff5599),
      //   fillOpacity: 0.2
      // })
    });
    // chart.plotContainer.children.push(buttonsContainer);
    root.container.children.push(containerButtons);

    xyChartAddButtons(root, containerButtons, data);

    for (let i = 0; i < data.states.length; i += 1) {
      if (data.states[i].active) {
        initialStateIndex = i;
        break;
      }
    }
  }

  xyChartToggleState(root, data, initialStateIndex);
  chart.appear(1600, 100);
}
