export function setCookie(key: string, value: string, expiresInDays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + (expiresInDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${key}=${value};${expires};path=/;SameSite=Lax`;
}

export function getCookie(key: string): null | string {
  const cookies = document.cookie.split(';');
  let foundCookie = null as null | string;
  cookies.forEach((cookieString) => {
    if (foundCookie === null) {
      let processedCookieString = cookieString;
      while (processedCookieString.charAt(0) === ' ') {
        processedCookieString = processedCookieString.substring(1);
      }
      if (processedCookieString.indexOf(`${key}=`) === 0) {
        foundCookie = processedCookieString.substring(key.length + 1, processedCookieString.length);
      }
    }
  });
  return foundCookie;
}
