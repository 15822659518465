import {
  COLORS_MAP,
  COLOR_FALLBACK,
  HIGHLIGHT_FIND,
  HIGHLIGHT_REPLACE,
} from '@/charts/globals';

export default function preprocessData(data: any) {
  // substitute colors
  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    let matchFoundFlag = false;
    for (let i = 0; i < COLORS_MAP.length; i += 1) {
      try {
        if (item.color.includes(COLORS_MAP[i][0])) {
          item.color = item.color.replace(COLORS_MAP[i][0], COLORS_MAP[i][1]);
          matchFoundFlag = true;
          break;
        }
        // eslint-disable-next-line no-empty
      } catch (e) { }
    }
    if (!matchFoundFlag) {
      try {
        item.color = COLOR_FALLBACK;
        // eslint-disable-next-line no-empty
      } catch (e) { }
    }
  }

  // highlight examples
  // eslint-disable-next-line no-restricted-syntax
  for (const item of data) {
    try {
      item.examples = item.examples.replaceAll(HIGHLIGHT_FIND, HIGHLIGHT_REPLACE);
      // item.examples = item.examples.replace(
      //   new RegExp(HIGHLIGHT_FIND, 'g'),
      //   HIGHLIGHT_REPLACE
      // );
      // eslint-disable-next-line no-empty
    } catch (e) { }
  }
}
