import getDigitsCount from '@/charts/getDigitsCount';

export default function xyChartGetYAxisMax(data: any) {
  let maximum = data.yaxismaximum;

  if (typeof maximum === 'undefined') {
    maximum = 0;
    for (let i = 0; i < data.chart.length; i += 1) {
      if (data.chart[i].frequency > maximum) {
        maximum = data.chart[i].frequency;
      }
    }

    const digits = getDigitsCount(maximum);
    const x = 10 ** (digits - 1); // Math.pow(10, digits - 1);
    maximum = (Math.round(maximum / x) + 1) * x;
  }

  return maximum;
}
