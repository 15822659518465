import { Root } from '@amcharts/amcharts5';
import xyChartFindChart from '@/charts/xyChartFindChart';
import xyChartAddRange from '@/charts/xyChartAddRange';
import * as GLB from '@/charts/globals';

export default function xyChartAddRangesSingle(root: Root, sortedData: any, valueKey: any, label: any) {
  const chart = xyChartFindChart(root) as any;
  const xAxis = chart.xAxes.getIndex(0);

  xAxis.axisRanges.clear();

  xyChartAddRange(
    xAxis,
    label,
    sortedData[0][valueKey],
    sortedData[sortedData.length - 1][valueKey],
  );

  // add last range grid
  const range = xAxis.makeDataItem({});
  xAxis.createAxisRange(range);
  range.set('category', sortedData[sortedData.length - 1][valueKey]);
  const tick = range.get('tick');
  tick.setAll({
    visible: true,
    strokeOpacity: GLB.OPACITY_GRID,
    length: GLB.RANGE_TICK_LENGTH,
    location: 1,
  });
  const grid = range.get('grid');
  grid.setAll({
    strokeOpacity: GLB.OPACITY_GRID,
    location: 1,
  });
}
