import csDonutChartMake from '@/charts/csDonutChartMake';
import reportError from '@/charts/reportError';

export default function drawResponsiveCSDonutChart(data: any, divId: string, n = 0) {
  // am5.ready(function () {
  //   csDonutChartMake(data, chartElementId, legendElementId);
  // });

  // or we can use a nice workaround below

  try {
    // am5.ready(function () { csDonutChartMake(data, divId); });
    csDonutChartMake(data, divId);
    // setTimeout(function () { csDonutChartMake(data, divId, n) }, 1000);
  } catch (e) {
    console.log(`csDonutChartMake() catch: ${n}--${e}`);
    if (n > 3) {
      const status = 'ERROR: drawDonutChart - failed to draw chart';
      console.log(status);
      reportError(status, divId);
      return;
    }
    const newN = n + 1;
    console.log(`csDonutChartMake() setting timeout.... ${newN}`);
    setTimeout(() => { drawResponsiveCSDonutChart(data, divId, newN); }, 100);
  }
}
