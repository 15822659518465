import { createStore } from 'vuex';
import { getCookie, setCookie } from '@/helpers/cookies';
import websiteConfig from '@/settings';

class TimeHandler {
  lastTime: number;

  constructor() {
    this.lastTime = (window as any).startTime || new Date().getTime();
  }

  log(s: string) {
    const newTime = new Date().getTime();
    // console.log(`${newTime - this.lastTime}ms ${s}`);
    this.lastTime = newTime;
  }
}

function initAudioContext(): AudioContext | null {
  const AvailableAudioContext = window.AudioContext || window.webkitAudioContext;
  try {
    return new AvailableAudioContext();
  } catch (error) {
    return null;
  }
}

async function resumeAudioContextIfNecessary(audioContext: AudioContext | null): Promise<AudioContext | null> {
  if (audioContext && (audioContext.state === 'suspended' || (audioContext.state as any) === 'interrupted')) await audioContext.resume();
  return audioContext;
}

async function decodeAudio(audioContext: AudioContext, audioArrayBuffer: ArrayBuffer): Promise<AudioBuffer | null> {
  return new Promise((resolve, reject) => {
    audioContext.decodeAudioData(audioArrayBuffer, (buffer: AudioBuffer) => {
      resolve(buffer);
    }, (e: any) => { reject(e); });
  });
}

async function downloadPhonemesAudio(): Promise<ArrayBuffer | null> {
  const response = await fetch(`${websiteConfig.websiteUrl}/data-ipa-chart/phonemes.mp3`);
  if (response.ok) {
    return response.arrayBuffer();
  }
  return null;
}

export default createStore({
  state: {
    acceptedCookie: true,
    audioContextInstance: null as null | AudioContext,
    phonemesAudioBuffer: null as null | AudioBuffer,
    timeHandler: null as TimeHandler | null,
    notFoundRedirectsNum: 0,
  },
  getters: {
    async audioContext(state): Promise<AudioContext | null> {
      if (!state.audioContextInstance) state.audioContextInstance = initAudioContext();
      return resumeAudioContextIfNecessary(state.audioContextInstance);
    },
    async phonemesAudioBuffer(state): Promise<AudioBuffer | null> {
      if (!state.audioContextInstance) state.audioContextInstance = initAudioContext();
      if (!state.phonemesAudioBuffer) {
        const arrayBuffer = await downloadPhonemesAudio();
        if (state.audioContextInstance && arrayBuffer) {
          state.phonemesAudioBuffer = await decodeAudio(state.audioContextInstance, arrayBuffer);
        }
      }
      return state.phonemesAudioBuffer;
    },
    timeHandler(state) {
      if (!state.timeHandler) state.timeHandler = new TimeHandler();
      return state.timeHandler;
    },
  },
  mutations: {
    readAcceptedCookie(state) {
      const accepted = getCookie('acceptedCookie');
      if (accepted !== null) {
        state.acceptedCookie = accepted === 'true';
      } else {
        state.acceptedCookie = false;
      }
    },
    setAcceptedCookie(state) {
      state.acceptedCookie = true;
      setCookie('acceptedCookie', 'true', 400);
    },
    clearNotFoundRedirectsNum(state) {
      state.notFoundRedirectsNum = 0;
    },
    increaseNotFoundRedirectsNum(state) {
      state.notFoundRedirectsNum += 1;
    },
  },
  actions: {},
  modules: {},
});
