import { Root } from '@amcharts/amcharts5';
import xyChartFindChart from '@/charts/xyChartFindChart';
import xyChartAddRange from '@/charts/xyChartAddRange';
import * as GLB from '@/charts/globals';

export default function xyChartAddRanges(root: Root, sortedData: any, rangeKey: any, valueKey: any) {
  const chart = xyChartFindChart(root) as any;
  const xAxis = chart.xAxes.getIndex(0);

  xAxis.axisRanges.clear();
  // amChrats 5.0.1 bug (fixed in 5.1.0) workaround:
  // xAxis.axisRanges.each(range=>{xAxis.removeDispose(range);});

  // traverse data to find range boundaries
  let rangeStartIndex = 0;
  for (let i = 0; i < sortedData.length; i += 1) {
    if (sortedData[i][rangeKey] !== sortedData[rangeStartIndex][rangeKey]) {
      xyChartAddRange(
        xAxis,
        sortedData[rangeStartIndex][rangeKey],
        sortedData[rangeStartIndex][valueKey],
        sortedData[i - 1][valueKey],
      );
      rangeStartIndex = i;
    }
  }

  // add the last meaningful range
  xyChartAddRange(
    xAxis,
    sortedData[rangeStartIndex][rangeKey],
    sortedData[rangeStartIndex][valueKey],
    sortedData[sortedData.length - 1][valueKey],
  );

  // add last range grid
  const range = xAxis.makeDataItem({});
  xAxis.createAxisRange(range);
  range.set('category', sortedData[sortedData.length - 1][valueKey]);
  const tick = range.get('tick');
  tick.setAll({
    visible: true,
    strokeOpacity: GLB.OPACITY_GRID,
    length: GLB.RANGE_TICK_LENGTH,
    location: 1,
  });
  const grid = range.get('grid');
  grid.setAll({
    strokeOpacity: GLB.OPACITY_GRID,
    // stroke: 1,
    location: 1,
  });
}
