import * as am5 from '@amcharts/amcharts5';

// COLOR

export const COLOR_TEXT:am5.Color = am5.color(0x404040);
export const COLOR_TEXT_HIGHLIGHTED = am5.color(0xCB4C57); // '#CB4C57';

export const COLOR_STROKE:am5.Color = am5.color(0x404040);

export const COLOR_FILL_LIGHT:am5.Color = am5.color(0xFFFFFF);

export const COLOR_BUTTON_DOWN:am5.Color = am5.color(0xFF0000);
export const COLOR_BUTTON_HOWER:am5.Color = am5.color(0x00FF00);
export const COLOR_BUTTON_ACTIVE:am5.Color = am5.color('#ffbd17'); // fc8363ff

export const COLOR_XY_COLUMN_DEFAULT:am5.Color = am5.color(0xDCE1E5);
export const COLOR_XY_COLUMN_HIGHLIGHTED:am5.Color = am5.color(0xFC8363);

export const COLOR_SA_GRAY:am5.Color = am5.color(0xdce1e5);
export const COLOR_SA_VIOLET:am5.Color = am5.color(0xb18be8);
export const COLOR_SA_FUCHSIA:am5.Color = am5.color(0xe24b88);
export const COLOR_SA_RED:am5.Color = am5.color(0xf34b55);
export const COLOR_SA_ORANGE:am5.Color = am5.color(0xfc8363);
export const COLOR_SA_YELLOW:am5.Color = am5.color(0xffbd17);
export const COLOR_SA_GREEN:am5.Color = am5.color(0x4ed1a1);
export const COLOR_SA_CYAN:am5.Color = am5.color(0x4cd3d6);
export const COLOR_SA_LIGHTBLUE:am5.Color = am5.color(0xb3e3ff);
export const COLOR_SA_BLUE:am5.Color = am5.color(0x59afe1);

export const COLOR_FALLBACK = am5.color(0xffffff);

// MARGIN
export const MARGIN_SMALL = 4;

// STROKE
export const STROKE_BOLD = 3; // not in use
export const STROKE_REGULAR = 2; // 1.6
export const STROKE_LIGHT = 1;

// RADIUS
export const RADIUS_SMALL = 6;
export const RADIUS_REGULAR = 6;

// FONT
export const FONT_SIZE_BIG = '1.125rem';
export const FONT_SIZE_REGULAR = '1rem';
export const FONT_SIZE_SMALL = '0.825rem';

// STUFF
export const OPACITY_GRID = 0.4;
export const RANGE_TICK_LENGTH = 40;

// SORT

export const COLORS_MAP = [
  ['#gray', '#dce1e5ff'],
  ['#red', '#f34b55ff'],
  ['#orange', '#fc8363ff'],
  ['#yellow', '#ffbd17ff'],
  ['#green', '#4ed1a1ff'],
  ['#cyan', '#4cd3d6ff'],
  ['#blue', '#59afe1ff'],
  ['#lightblue', '#b3e3ffff'],
  ['#violet', '#b18be8ff'],
  ['#fuchsia', '#e24b88ff'],
];

export const RESPONSIVE_THRESHOLD = 522;

export const HIGHLIGHT_FIND = 'HL';
export const HIGHLIGHT_REPLACE = '#CB4C57';
